import {Injectable} from '@angular/core'
import {DbService, KeyValueItem} from "../db/db.service"
import {Table} from "dexie"
import {DateTime} from "luxon"


@Injectable({
    providedIn: 'root'
})
export class SettingsDbService {

    settingsTable: Table<KeyValueItem>


    constructor(
        private dbService: DbService,
    ) {
        this.settingsTable = this.dbService.settingsTable
    }


    // Speichert einen Eintrag im Store mit TTL
    async setValue<T>(key: string, value: T, ttlSeconds: number = null) {
        // TTL
        let ttl: string = null
        if (ttlSeconds !== null) {
            ttl = DateTime.now().toUTC().plus({"seconds": ttlSeconds}).toISO()
        }
        // Speichern
        const json = JSON.stringify(value)
        await this.settingsTable.put({key, json, ttl})
    }


    // Läd einen Eintrag aus dem Store mit TTL-Prüfung
    async getValue<T>(key: string): Promise<T> {
        let rawData: KeyValueItem = await this.settingsTable.get(key)
        if (!rawData) {
            return null
        }

        // TTL prüfen
        if (rawData?.ttl) {
            if (DateTime.fromISO(rawData.ttl) < DateTime.now()) {
                // TTL abgelaufen
                await this.settingsTable.delete(key)
                return null
            }
        }

        // Fertig
        const value = JSON.parse(rawData.json)
        return value
    }


    async delValue(key: string) {
        await this.settingsTable.delete(key)
    }

}
