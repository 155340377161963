import {WeatherConditions} from "./app.interfaces"


const ICONS_DIR = "/assets/images/weather-icons"
const BG_DIR = "/assets/images/weather-backgrounds"

// Test-Video: https://directus.mobilewetter.com/assets/d78f8713-c9ca-4df3-908a-9c402f795a27


// Video Einbau
// day: {
//     icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_easy.svg`,
//     // backgroundImage: `${BG_DIR}/tag_regenschauer.webp`,
//     backgroundVideoUrl: `${BG_DIR}/xxx.mp4`,
//     backgroundColor: "#859EAE",
//     description: "Regenschauer"
// },


export const WEATHER_CONDITIONS: WeatherConditions = {
    "cloud_bright_rain_drizzle": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_rain_drizzle.svg`,
            backgroundImage: `${BG_DIR}/tag_regenschauer.webp`,
            backgroundColor: "#566e81",
            description: "Leichter Nieselregen"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_rain_drizzle.svg`,
            backgroundImage: `${BG_DIR}/nacht_regenschauer.webp`,
            backgroundColor: "#131b24",
            description: "Leichter Nieselregen"
        }
    },
    "cloud_bright_rain_easy": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_rain_easy.svg`,
            backgroundImage: `${BG_DIR}/tag_regenschauer.webp`,
            backgroundColor: "#566e81",
            description: "Regenschauer"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_rain_easy.svg`,
            backgroundImage: `${BG_DIR}/nacht_regenschauer.webp`,
            backgroundColor: "#131b24",
            description: "Regenschauer"
        }
    },
    "cloud_bright_rain_hard": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_rain_hard.svg`,
            backgroundImage: `${BG_DIR}/tag_regen.webp`,
            backgroundColor: "#4f6272",
            description: "Regen"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_rain_hard.svg`,
            backgroundImage: `${BG_DIR}/nacht_regen.webp`,
            backgroundColor: "#151f28",
            description: "Regen"
        }
    },
    "cloud_bright_rain_snow_easy": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_rain_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/tag_schneeregen.webp`,
            backgroundColor: "#1e7da1",
            description: "Leichter Schneeregen"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_rain_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneeregen.webp`,
            backgroundColor: "#41494f",
            description: "Leichter Schneeregen"
        }
    },
    "cloud_bright_rain_snow_hard": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_rain_snow_hard.svg`,
            backgroundImage: `${BG_DIR}/tag_schneeregen.webp`,
            backgroundColor: "#1e7da1",
            description: "Schneeregen"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_rain_snow_hard.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneeregen.webp`,
            backgroundColor: "#41494f",
            description: "Schneeregen"
        }
    },
    "cloud_bright_snow_drizzle": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_snow_drizzle.svg`,
            backgroundImage: `${BG_DIR}/tag_schneefall.webp`,
            backgroundColor: "#2180a4",
            description: "Leichter Schneefall"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_snow_drizzle.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneefall.webp`,
            backgroundColor: "#4c545a",
            description: "Leichter Schneefall"
        }
    },
    "cloud_bright_snow_easy": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/tag_schneefall.webp`,
            backgroundColor: "#2180a4",
            description: "Schneefall"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneefall.webp`,
            backgroundColor: "#4c545a",
            description: "Schneefall"
        }
    },
    "cloud_bright_snow_hard": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_bright_snow_hard.svg`,
            backgroundImage: `${BG_DIR}/tag_schneefall.webp`,
            backgroundColor: "#2180a4",
            description: "Schneefall"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_bright_snow_hard.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneefall.webp`,
            backgroundColor: "#4c545a",
            description: "Schneefall"
        }
    },
    "cloud_thunder_rain": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_thunder_rain.svg`,
            backgroundImage: `${BG_DIR}/tag_gewitter.webp`,
            backgroundColor: "#4e5460",
            description: "Gewitter"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_thunder_rain.svg`,
            backgroundImage: `${BG_DIR}/nacht_gewitter.webp`,
            backgroundColor: "#181819",
            description: "Gewitter"
        }
    },
    "cloud_thunder_rain_snow": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_thunder_rain_snow.svg`,
            backgroundImage: `${BG_DIR}/tag_gewitter.webp`,
            backgroundColor: "#4e5460",
            description: "Gewitter"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_thunder_rain_snow.svg`,
            backgroundImage: `${BG_DIR}/nacht_gewitter.webp`,
            backgroundColor: "#181819",
            description: "Gewitter"
        }
    },
    "cloud_thunder_snow": {
        day: {
            icon: `${ICONS_DIR}/day_cloud_thunder_snow.svg`,
            backgroundImage: `${BG_DIR}/tag_gewitter.webp`,
            backgroundColor: "#4e5460",
            description: "Gewitter"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloud_thunder_snow.svg`,
            backgroundImage: `${BG_DIR}/nacht_gewitter.webp`,
            backgroundColor: "#181819",
            description: "Gewitter"
        }
    },
    "cloudy_bright": {
        day: {
            icon: `${ICONS_DIR}/day_cloudy_bright.svg`,
            backgroundImage: `${BG_DIR}/tag_bewoelkt.webp`,
            backgroundColor: "#406276",
            description: "Bewölkt"
        },
        night: {
            icon: `${ICONS_DIR}/night_cloudy_bright.svg`,
            backgroundImage: `${BG_DIR}/nacht_bewoelkt.webp`,
            backgroundColor: "#021224",
            description: "Bewölkt"
        }
    },
    "sun_bright_cloud": {
        day: {
            icon: `${ICONS_DIR}/day_sun_bright_cloud.svg`,
            backgroundImage: `${BG_DIR}/tag_teilweise_bewoelkt.webp`,
            backgroundColor: "#227ab4",
            description: "Teilweise bewölkt"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_bright_cloud.svg`,
            backgroundImage: `${BG_DIR}/nacht_teilweise_bewoelkt.webp`,
            backgroundColor: "#0e171d",
            description: "Teilweise bewölkt"
        }
    },
    "sun_cloud_bright_rain_drizzle": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_drizzle.svg`,
            backgroundImage: `${BG_DIR}/tag_regenschauer.webp`,
            backgroundColor: "#566e81",
            description: "Leichter Nieselregen"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_rain_drizzle.svg`,
            backgroundImage: `${BG_DIR}/nacht_regenschauer.webp`,
            backgroundColor: "#131b24",
            description: "Leichter Nieselregen"
        }
    },
    "sun_cloud_bright_rain_easy": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_easy.svg`,
            backgroundImage: `${BG_DIR}/tag_regenschauer.webp`,
            backgroundColor: "#566e81",
            description: "Regenschauer"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_rain_easy.svg`,
            backgroundImage: `${BG_DIR}/nacht_regenschauer.webp`,
            backgroundColor: "#131b24",
            description: "Regenschauer"
        }
    },
    "sun_cloud_bright_rain_hard": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_hard.svg`,
            backgroundImage: `${BG_DIR}/tag_regen.webp`,
            backgroundColor: "#4f6272",
            description: "Regen"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_rain_hard.svg`,
            backgroundImage: `${BG_DIR}/nacht_regen.webp`,
            backgroundColor: "#151f28",
            description: "Regen"
        }
    },
    "sun_cloud_bright_rain_snow": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_snow.svg`,
            backgroundImage: `${BG_DIR}/tag_schneeregen.webp`,
            backgroundColor: "#1e7da1",
            description: "Schneeregen"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_rain_snow.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneeregen.webp`,
            backgroundColor: "#41494f",
            description: "Schneeregen"
        }
    },
    "sun_cloud_bright_rain_snow_easy": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/tag_schneeregen.webp`,
            backgroundColor: "#1e7da1",
            description: "Leichter Schneeregen"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_rain_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneeregen.webp`,
            backgroundColor: "#41494f",
            description: "Leichter Schneeregen"
        }
    },
    "sun_cloud_bright_rain_snow_thunder": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_snow_thunder.svg`,
            backgroundImage: `${BG_DIR}/tag_schneeregen.webp`,
            backgroundColor: "#1e7da1",
            description: "Schneeregen"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_rain_snow_thunder.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneeregen.webp`,
            backgroundColor: "#41494f",
            description: "Schneeregen"
        }
    },
    "sun_cloud_bright_rain_thunder": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_rain_thunder.svg`,
            backgroundImage: `${BG_DIR}/tag_gewitter.webp`,
            backgroundColor: "#4e5460",
            description: "Gewitter"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_rain_thunder.svg`,
            backgroundImage: `${BG_DIR}/nacht_gewitter.webp`,
            backgroundColor: "#181819",
            description: "Gewitter"
        }
    },
    "sun_cloud_bright_snow_drizzle": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_snow_drizzle.svg`,
            backgroundImage: `${BG_DIR}/tag_schneefall.webp`,
            backgroundColor: "#2180a4",
            description: "Leichter Schneefall"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_snow_drizzle.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneefall.webp`,
            backgroundColor: "#4c545a",
            description: "Leichter Schneefall"
        }
    },
    "sun_cloud_bright_snow_easy": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/tag_schneefall.webp`,
            backgroundColor: "#2180a4",
            description: "Schneefall"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_snow_easy.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneefall.webp`,
            backgroundColor: "#4c545a",
            description: "Schneefall"
        }
    },
    "sun_cloud_bright_snow_hard": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_snow_hard.svg`,
            backgroundImage: `${BG_DIR}/tag_schneefall.webp`,
            backgroundColor: "#2180a4",
            description: "Schneefall"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_snow_hard.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneefall.webp`,
            backgroundColor: "#4c545a",
            description: "Schneefall"
        }
    },
    "sun_cloud_bright_snow_thunder": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloud_bright_snow_thunder.svg`,
            backgroundImage: `${BG_DIR}/tag_schneefall.webp`,
            backgroundColor: "#2180a4",
            description: "Schneefall"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloud_bright_snow_thunder.svg`,
            backgroundImage: `${BG_DIR}/nacht_schneefall.webp`,
            backgroundColor: "#4c545a",
            description: "Schneefall"
        }
    },
    "sun_cloudy_bright": {
        day: {
            icon: `${ICONS_DIR}/day_sun_cloudy_bright.svg`,
            backgroundImage: `${BG_DIR}/tag_teilweise_bewoelkt.webp`,
            backgroundColor: "#227ab4",
            description: "Meist bewölkt"
        },
        night: {
            icon: `${ICONS_DIR}/night_sun_cloudy_bright.svg`,
            backgroundImage: `${BG_DIR}/nacht_teilweise_bewoelkt.webp`,
            backgroundColor: "#0e171d",
            description: "Meist bewölkt"
        }
    },
    "sunny": {
        day: {
            icon: `${ICONS_DIR}/day_sunny.svg`,
            backgroundImage: `${BG_DIR}/tag_wolkenlos.webp`,
            backgroundColor: "#0a71b2",
            description: "Wolkenlos"
        },
        night: {
            icon: `${ICONS_DIR}/night_sunny.svg`,
            backgroundImage: `${BG_DIR}/nacht_wolkenlos.webp`,
            backgroundColor: "#0e1820",
            description: "Wolkenlos"
        }
    },
}

export const WIND_STRENGTH_NAMES = {
    "low": "Leichter",
    "moderate": "",
    "high": "Starker",
}

export const WIND_DIRECTION_NAMES = {
    "n": "Nordwind",
    "ne": "Nordostwind",
    "e": "Ostwind",
    "se": "Südostwind",
    "s": "Südwind",
    "sw": "Südwestwind",
    "w": "Westwind",
    "nw": "Nordwestwind",
    "xx": "Wind"
}

export const WIND_DIRECTION_TRANSLATIONS = {
    "n": "N",
    "ne": "NO",
    "e": "O",
    "se": "SO",
    "s": "S",
    "sw": "SW",
    "w": "W",
    "nw": "NW",
    "xx": ""
}

export const MW_STARTPAGE_BACKGROUND_COLOR: string = "MW_STARTPAGE_BACKGROUND_COLOR"
export const MW_STARTPAGE_BACKGROUND_IMAGE: string = "MW_STARTPAGE_BACKGROUND_IMAGE"
export const MW_STARTPAGE_BACKGROUND_POSITION: string = "MW_STARTPAGE_BACKGROUND_POSITION"
export const MW_STARTPAGE_BACKGROUND_SIZE: string = "MW_STARTPAGE_BACKGROUND_SIZE"
export const MW_STARTPAGE_TEASER_UUID: string = "MW_STARTPAGE_TEASER_UUID"
export const MW_USER_ID: string = "MW_USER_ID"
