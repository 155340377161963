import {NgModule} from '@angular/core'
import {PreloadAllModules, RouterModule, Routes} from '@angular/router'


const routes: Routes = [
    {
        path: "",
        redirectTo: "/start",
        pathMatch: "full",
    },
    {
        path: "start",
        pathMatch: "full",
        loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
    },
    {
        path: "weather", pathMatch: "full",
        loadChildren: () => import('./pages/weather/weather.module').then(m => m.WeatherPageModule)
    },
    {
        path: "places",
        loadChildren: () => import('./pages/places/places.module').then(m => m.PlacesPageModule)
    },
    {
        path: "helper",
        loadChildren: () => import('./pages/helper/helper.module').then(m => m.HelperPageModule)
    },
]


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
