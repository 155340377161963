import {LOCALE_ID, NgModule, Provider, Type, ModuleWithProviders, Injectable, ErrorHandler} from "@angular/core"
import {BrowserModule} from "@angular/platform-browser"
import {RouteReuseStrategy} from "@angular/router"
import {registerLocaleData} from "@angular/common"
import localeDe from "@angular/common/locales/de"
import {IonicModule, IonicRouteStrategy, Platform} from "@ionic/angular"
import {AppComponent} from "./app.component"
import {AppRoutingModule} from "./app-routing.module"
import {environment} from "../environments/environment"
import {ServiceWorkerModule} from '@angular/service-worker'
import {HttpClientModule} from "@angular/common/http"
import * as sentry from "@sentry/angular-ivy"


// Locale
registerLocaleData(localeDe)


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor(
        private platform: Platform
    ) {
    }


    handleError(error: any) {
        console.error(error)
        const platforms = this.platform.platforms() || null
        if (!!platforms) {
            sentry.setTag("platform", platforms.join(", "))
        }
        sentry.captureException(error.originalError || error)
    }
}


const providers: Provider[] = [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: "de-DE"},
]


// Sentry
function initSentry() {

    if ("glitchTip" in environment) {

        // Initialisieren
        sentry.addTracingExtensions()
        sentry.init({
            dsn: environment.glitchTip["dsn"],
            release: `${environment.build}-${environment.version}`,
            autoSessionTracking: false,
            attachStacktrace: true,
            maxValueLength: 2000,
            maxBreadcrumbs: 10,
            tracesSampleRate: environment.glitchTip["tracesSampleRate"],
            ignoreErrors: ["ChunkLoadError"]
        })

        // ErrorHandler hinzufügen
        providers.push({provide: ErrorHandler, useClass: SentryErrorHandler},)

    }
}


initSentry()


const moduleImports: Array<Type<any> | ModuleWithProviders<{}> | any[]> = [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
        backButtonText: ""
    }),
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
        enabled: environment.serviceWorker,
        // Register the ServiceWorker as soon as the app is stable
        // or after 10 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:10000'
    }),
]


@NgModule({
    declarations: [AppComponent],
    imports: moduleImports,
    providers,
    bootstrap: [AppComponent],
})
export class AppModule {
}
