import {Injectable} from '@angular/core'
import Dexie, {Table} from "dexie"
import {Place} from "../../app.interfaces"


export interface KeyValueItem {
    key: string
    json: string
    ttl: string
}


class AppDb extends Dexie {

    // Tabellen
    places!: Table<Place, string>
    settings!: Table<KeyValueItem, string>
    coordinates!: Table<KeyValueItem, string>
    forecasts!: Table<KeyValueItem, string>
    placeTeasers!: Table<KeyValueItem, string>


    constructor() {
        super("mw")

        // Stores
        // Es werden nur die Felder eingetragen, die indiziert werden sollen.
        // Bei jeder Änderung muss die Version erhöht werden
        this.version(3).stores({
            places: "&place_id, sort_order",
            settings: "&key",
            coordinates: "&key",
            forecasts: "&key",
            placeTeasers: "&key",
        })

    }


}


@Injectable({
    providedIn: 'root'
})
export class DbService {

    placesTable = new AppDb().places
    settingsTable = new AppDb().settings
    coordinatesTable = new AppDb().coordinates
    forecastsTable = new AppDb().forecasts
    placeTeasersTable = new AppDb().placeTeasers


    constructor() {
    }
}

